/* eslint-disable no-restricted-syntax */
import { toast } from 'react-toastify';
import { IAPIResponseStatus, IOptionValue } from '../interfaces';
import { IDbLookUps, IFieldDataNew } from '../interfaces/field';
import { IDocumentData, IField } from '../interfaces/document';
import { getRequest, postRequest, putRequest, deleteRequest } from '../utils/api-request';

const getField = async (
    fieldId: number,
): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    try {
        const res = await getRequest(`document-types/fields/${fieldId}`);
        if (res?.apiStatus === 'SUCCESS') {
            return { apiStatus: res.apiStatus };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const createField = async (
    data: IFieldDataNew,
): Promise<{ apiStatus: IAPIResponseStatus; fieldId: number } | undefined> => {
    try {
        const res = await postRequest(`document-types/fields`, data);
        if (res) {
            return { apiStatus: res?.apiStatus, fieldId: res.response.id };
        }

        return undefined;
    } catch (err) {
        return undefined;
    }
};

const bulkUpdateFields = async (
    fieldsList: IField[],
): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    try {
        const fields = [];
        for (const field of fieldsList) {
            fields.push({
                id: field.id,
                name: field.name,
                dynamicOptionValues: field.dynamicOptionValues,
                staticOptionValues: field.staticOptionValues,
                dataTypeId: field.dataType.value,
                uiControlId: field.uiControl.value,
            });
        }
        const res = await putRequest(`document-types/fields`, { fields });
        if (res) {
            return { apiStatus: res.apiStatus };
        }

        return undefined;
    } catch (err) {
        return undefined;
    }
};

const deleteField = async (
    fieldId: number,
): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    try {
        const res = await deleteRequest(`document-types/fields/${fieldId}`);
        if (res) {
            return { apiStatus: res?.apiStatus };
        }

        return undefined;
    } catch (err) {
        return undefined;
    }
};

const getDbLookUps = async (): Promise<
    { apiStatus: IAPIResponseStatus; dbLookUps: IDbLookUps[] } | undefined
> => {
    try {
        const res = await getRequest(`document-types/fields/db-lookups`);
        if (res && res.apiStatus === 'SUCCESS') {
            const dbLookUps: IDbLookUps[] = [];
            for (const dbLookUp of res.response) {
                const columns: IOptionValue[] = [];
                for (const col of dbLookUp.columns) {
                    columns.push({ label: col.name, value: col.name });
                }
                dbLookUps.push({
                    value: dbLookUp.id,
                    label: dbLookUp.tableName,
                    columns,
                });
            }
            return { apiStatus: res.apiStatus, dbLookUps };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getDynamicValues = async (
    fieldId: number,
    query?: string,
    list?: string[],
): Promise<{ apiStatus: IAPIResponseStatus; dynamicData: IOptionValue[] } | undefined> => {
    try {
        let queryString = 'limit=10&offset=0';
        if (list && list.length > 0) {
            queryString += `&list=${JSON.stringify(list)}`;
        }
        if (query) {
            queryString += `&query=${query}`;
        }
        const reqBody = {
            query: queryString,
        };
        // changed this to post request to avoid url length limitation in get request
        const res = await postRequest(`document-types/fields/${fieldId}/dynamic-values?`, reqBody);
        if (res?.apiStatus === 'SUCCESS') {
            const dynamicData: IOptionValue[] = [];
            for (const data of res.response) {
                dynamicData.push({ label: data.name, value: data.value });
            }
            if (query && query.length > 20) {
                const requested = query.split(',').length;
                const responded = res?.response.length;
                if (requested > responded)
                    toast.success(
                        `Added ${responded} and discarded ${requested - responded} user(s)`,
                    );
                else toast.success(`Added ${responded} user(s)`);
            }

            return { apiStatus: res.apiStatus, dynamicData };
        }
        return { apiStatus: res ? res.apiStatus : 'FAILURE', dynamicData: [] };
        // return undefined;
    } catch (err) {
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getDynamicValuesPRB = async (
    userId: number,
    fieldId: number,
    query?: string,
): Promise<{ apiStatus: IAPIResponseStatus; dynamicData: IOptionValue[] } | undefined> => {
    try {
        let queryString = 'limit=30&offset=0';
        if (userId) {
            queryString += `&managerId=${userId}`;
        }
        if (query) {
            queryString += `&query=${query}`;
        }
        const reqBody = {
            query: queryString,
        };
        // changed this to post request to avoid url length limitation in get request
        const res = await postRequest(
            `document-types/fields/${fieldId}/dynamic-values-autofill?`,
            reqBody,
        );
        if (res?.apiStatus === 'SUCCESS') {
            const dynamicData: IOptionValue[] = [];
            for (const data of res.response) {
                dynamicData.push({ label: data.name, value: data.value });
            }
            if (query && query.length > 20) {
                const requested = query.split(',').length;
                const responded = res?.response.length;
                if (requested > responded)
                    toast.success(
                        `Added ${responded} and discarded ${requested - responded} user(s)`,
                    );
                else toast.success(`Added ${responded} user(s)`);
            }

            return { apiStatus: res.apiStatus, dynamicData };
        }
        return { apiStatus: res ? res.apiStatus : 'FAILURE', dynamicData: [] };
        // return undefined;
    } catch (err) {
        return undefined;
    }
};

const getUserInfoByemail = async (
    email: string,
): Promise<{ apiStatus: IAPIResponseStatus; data: any } | undefined> => {
    try {
        const queryString = `?email=${email}`;
        const res = await getRequest(`users/get-user-info/${queryString}`);
        if (res?.apiStatus === 'SUCCESS') {
            return { apiStatus: res.apiStatus, data: res.response };
        }
        return { apiStatus: res ? res.apiStatus : 'FAILURE', data: {} };
    } catch (error) {
        console.log(error);
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getDocuments = async (
    fieldId: number,
    query?: string,
    docYypeIds?: number[],
    documentList?: (string | number)[] | undefined,
    list?: string[],
): Promise<{ apiStatus: IAPIResponseStatus; dynamicData: IDocumentData[] } | undefined> => {
    try {
        let queryString = 'limit=10&offset=0';
        if (list && list.length > 0) {
            queryString += `&list=${JSON.stringify(list)}`;
        }
        if (docYypeIds) {
            for (let index = 0; index < docYypeIds.length; index += 1) {
                queryString += `&docTypeIds=${docYypeIds[index]}`;
            }
        }
        if (documentList) {
            for (let index = 0; index < documentList.length; index += 1) {
                queryString += `&documentList=${documentList[index]}`;
            }
        }
        if (query) {
            queryString += `&query=${query}`;
        }
        const res = await getRequest(
            `document-types/fields/${fieldId}/search-document?${queryString}`,
        );
        if (res?.apiStatus === 'SUCCESS') {
            const dynamicData: IDocumentData[] = [];
            for (const data of res.response) {
                if (data.name === null) {
                    data.name = '';
                }
                dynamicData.push({
                    label: data.name,
                    value: data.value,
                    documentId: data.documentId,
                    documentVersionId: data.documentVersionId,
                    data: data.data,
                    minorVersion: data.minorVersion || 0,
                    majorVersion: data.majorVersion || 0,
                    status: data.status
                });
            }
            return { apiStatus: res.apiStatus, dynamicData };
        }
        return { apiStatus: res ? res.apiStatus : 'FAILURE', dynamicData: [] };
        // return undefined;
    } catch (err) {
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getDocumentsReference = async (
    fieldId: number,
    query?: string,
    documentList?: (string | number)[] | undefined,
    partyName?: string,
    list?: string[],
): Promise<{ apiStatus: IAPIResponseStatus; dynamicData: IDocumentData[] } | undefined> => {
    try {
        let queryString = 'limit=10&offset=0';
        if (list && list.length > 0) {
            queryString += `&list=${JSON.stringify(list)}`;
        }
        if (documentList) {
            for (let index = 0; index < documentList.length; index += 1) {
                queryString += `&documentList=${documentList[index]}`;
            }
        }
        if (partyName) {
            queryString += `&partyName=${partyName}`;
        }
        if (query) {
            queryString += `&query=${query}`;
        }
        const res = await getRequest(
            `document-types/fields/${fieldId}/search-document-reference?${queryString}`,
        );
        if (res?.apiStatus === 'SUCCESS') {
            const dynamicData: IDocumentData[] = [];
            for (const data of res.response) {
                dynamicData.push({
                    label: data.name,
                    value: data.value,
                    documentId: data.documentId,
                    documentVersionId: data.documentVersionId,
                });
            }

            return { apiStatus: res.apiStatus, dynamicData };
        }
        return { apiStatus: res ? res.apiStatus : 'FAILURE', dynamicData: [] };
        // return undefined;
    } catch (err) {
        return undefined;
    }
};

export {
    getUserInfoByemail,
    getDynamicValuesPRB,
    getField,
    createField,
    deleteField,
    getDbLookUps,
    getDynamicValues,
    getDocuments,
    bulkUpdateFields,
    getDocumentsReference,
};
