/* eslint-disable max-lines-per-function */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Column, usePagination, useTable, useSortBy } from 'react-table';
import AsyncSelect from 'react-select/async';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import Toggle from '../toggle/Toggle';
import { SortIcon, TickIcon, CrossIcon, DeleteSvg } from '../../assets/images';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { IOptionValue, IPaginationData } from '../../interfaces';
import { IAdminDelegations, IAdminDelegationInfo } from '../../interfaces/delegation';
import { getAllDelegations, userAutoSearch, createDelegation, removeDelegation } from '../../API/delegation';
import { DateColumn } from '../General';
import Pagination from '../pagination/Pagination';
import { getNextDate, convertToEpoch } from '../../utils';
import STATIC_CONTENT from '../../constants/StaticContent';
import CONSTANTS from '../../constants';
import { SkeletonText } from '../Skeleton';
import './Delegations.scss';

const { DELEGATION_SETTINGS } = STATIC_CONTENT;

type AccessValue = true | false | undefined;
const DelegationAccessIcon = ({ value } : { value : AccessValue }) => (
    <div>
        {value ? (
            <TickIcon width="34px" height="34px" />
        ) : (
            <CrossIcon width="34px" height="34px" />
        )}
    </div>
);

const COLUMNS : Column<IAdminDelegations>[] = [
    {
        Header: 'Delegated From',
        accessor: 'delegatedFrom',
        minWidth: 200,
        disableSortBy: true,
        Cell: ({ value }) => <SkeletonText text={value} width={200} />,
    },
    {
        Header: 'Delegated To',
        accessor: 'delegatedTo',
        minWidth: 200,
        disableSortBy: true,
        Cell: ({ value }) => <SkeletonText text={value} width={200} />,
    },
    {
        Header: 'From',
        accessor: 'startDate',
        Cell: ({ value }) => <DateColumn value={value} />,
        minWidth: 100,
        disableSortBy: true,
    },
    {
        Header: 'To',
        accessor: 'endDate',
        Cell: ({ value }) => <DateColumn value={value} />,
        minWidth: 100,
        disableSortBy: true,
    },
    // {
    //     Header: 'Edit Access',
    //     accessor: 'editAccess',
    //     Cell: ({ value }) => <DelegationAccessIcon value={value} />,
    //     disableSortBy: true
    // },
    // {
    //     Header: 'Approval Access',
    //     accessor: 'approvalAccess',
    //     Cell: ({ value }) => <DelegationAccessIcon value={value} />,
    //     disableSortBy: true
    // },
    {
        Header: 'Delete',
        accessor: 'id',
        disableSortBy: true,
    }
];


const Delegations : React.FC = () => {
    const { user: employee } = useAuthDataContext();
    const today = new Date();
    const tomorrow = getNextDate(today);
    const dateFormat = 'MMM d, yyyy';

    const defaultNewDelegate: IAdminDelegationInfo = {
        delegateFrom: { value: 0, label: '' },
        delegateTo: { value:0, label: ''},
        startDate: today,
        endDate: tomorrow,
        editAccess: false,
        approvalAccess: false
    };

    const defaultDelegations: IAdminDelegations = {
        id: CONSTANTS.LOADING.NUMBER,
        delegatedFrom: CONSTANTS.LOADING.TEXT,
        delegatedTo: CONSTANTS.LOADING.TEXT,
        startDate: CONSTANTS.LOADING.DATE,
        endDate: CONSTANTS.LOADING.DATE,
        approvalAccess: false,
        editAccess: false,
        deleteEnable: false,
    }
    const DEFAULT_PAGINATION_DATA: IPaginationData = {
        pageIndex: 0,
        pageSize: 10,
        totalCount: 0,
    };
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const [newDelegation, setNewDelegation] = useState(defaultNewDelegate);
    const [usersLoading, setUsersLoading] = useState(false);
    const [delegateFromFilter,setDelegateFromFilter] = useState<string | null>(null);
    const [delegateToFilter,setDelegateToFilter] = useState<string | null>(null);
    const [searchText, setSearchText] = useState('');
    // const [editAccess, setEditAccess] = useState(false);
    // const [approvalAccess, setApprovalAccess] = useState(true);
    const [delegations, setDelegations] = useState<IAdminDelegations[]>([defaultDelegations]);
    const [delegationLoading, setDelegationLoading] = useState(false);
    const promiseOptions = async (inputValue: string): Promise<IOptionValue[]> => {
        setSearchText(inputValue);
        setUsersLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await userAutoSearch(inputValue, 'ADMIN');
                if (users) {
                    // users = users.filter((u) => u.value !== employee.id);
                    resolve(users);
                    setUsersLoading(false);
                }
            } else {
                setUsersLoading(false);
            }
        });
    };

    const handleFromUser = (delegateFrom: IOptionValue) => setNewDelegation({ ...newDelegation, delegateFrom });
    const handleToUser = (delegateTo: IOptionValue) => setNewDelegation({ ...newDelegation, delegateTo });
    const handleFromDate = (date: Date) => {
        if (newDelegation.endDate <= date) {
            setNewDelegation({ ...newDelegation, startDate: date, endDate: getNextDate(date) });
        } else {
            setNewDelegation({ ...newDelegation, startDate: date });
        }
    };
    const handleToDate = (date: Date) => setNewDelegation({ ...newDelegation, endDate: date });

    const columns = useMemo(() => COLUMNS, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        previousPage,
        nextPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data: delegations || [],
            manualPagination: true,
            autoResetPage: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            },
        },
        useSortBy,
        usePagination,
    );

    function debounce<Params extends any[]>(
        func: (...args: Params) => any,
        timeout: number,
    ): (...args: Params) => void {
        let timer: NodeJS.Timeout;
        return (...args: Params) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, timeout);
        };
    }

    const updateDelegations = async (filters : any) => { 
        console.log(delegateFromFilter,delegateToFilter);
        const size = pageSize ?? pagination.pageSize;
        const index = pageIndex ?? pagination.pageIndex;
        const delegatedFrom: string = (filters.delegateFromFilter == null ? '' : filters.delegateFromFilter);
        const delegatedTo: string = (filters.delegateToFilter == null ? '' : filters.delegateToFilter);
        const allDelegations = await getAllDelegations(size, size*index, delegatedFrom, delegatedTo);
        if (allDelegations) {
            setDelegations(allDelegations?.delegations);
            setPagination({
                pageSize: size,
                pageIndex: index,
                totalCount: allDelegations.count,
            });
        }
    };

    const debouncedUpdateDelegations = useCallback(
        debounce((filters) => {
          updateDelegations(filters);
        }, 500),
        []
      );
      
    useEffect(() => {
        debouncedUpdateDelegations({ delegateFromFilter, delegateToFilter });
    }, [delegateFromFilter, delegateToFilter]);

    const deleteDelegation = async (rowId: any) => {
        const response = await removeDelegation(rowId);
        if (response?.apiStatus === 'SUCCESS') {
            updateDelegations({delegateFromFilter,delegateToFilter});
        } else {
            toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.UNABLE_TO_DELETE);
        }
    }

    const handleSave = async () => {
        setDelegationLoading(true);
        if (newDelegation.startDate > newDelegation.endDate) {
            toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.INVALID_DATES);
        } else if (!newDelegation.delegateFrom.value || !newDelegation.delegateTo.value) {
            toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.USERS_NOT_CHOSEN);
        } else if (newDelegation.delegateFrom.value === newDelegation.delegateTo.value) {
            toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.USERS_SAME);
        } else {
            const formData = {
                delegatorId: newDelegation.delegateFrom.value,
                delegateeId: newDelegation.delegateTo.value,
                startTime: convertToEpoch(newDelegation.startDate),
                endTime: convertToEpoch(newDelegation.endDate),
                editAccess: false,
                approvalAccess: true,
                requestFrom: 'DMS',
                isAdmin: true
            };
            const responseData = await createDelegation(formData);
            if (responseData?.apiStatus === 'SUCCESS') {
                toast.success(DELEGATION_SETTINGS.TOAST.SUCCESS_MSG.DELEGATED);
                updateDelegations({delegateFromFilter,delegateToFilter});
                setNewDelegation(defaultNewDelegate);
                // setEditAccess(false);
                // setApprovalAccess(false);
            } else if (responseData?.error.includes('MULTIPLE_DELEGATIONS_FOR_DELEGATOR')) {
                toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.MULTIPLE_DELEGATIONS_FOR_DELEGATOR);
            } else if (responseData?.error.includes('DELEGATEE_UNAVAILABLE')) {
                toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.DELEGATEE_UNAVAILABLE);
            } else {
                toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
            }
        }
        setDelegationLoading(false);
    }

    useEffect(() => {
        updateDelegations({delegateFromFilter,delegateToFilter});
    }, [pageIndex, pageSize]);

    // useEffect(() => {
    //     setNewDelegation({ ...newDelegation, editAccess });
    // }, [editAccess]);

    // useEffect(() => {
    //     setNewDelegation({ ...newDelegation, approvalAccess });
    // }, [approvalAccess]);

    return (
        <div className="all-delegations">
            <div className="delegation-form">
                <div className = "create-delegation-header">
                    Create Delegation
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label>Delegate From</label>
                        <AsyncSelect
                            name="delegateFrom"
                            id="delegateFrom"
                            className="react-select-delegate-from"
                            value={newDelegation.delegateFrom}
                            isLoading={usersLoading}
                            hideSelectedOptions
                            loadOptions={promiseOptions}
                            onChange={(option) => {
                                if (option) handleFromUser(option);
                            }}
                            noOptionsMessage={() =>
                                searchText.length >= 3
                                    ? 'No results found'
                                    : 'Start typing minimum 3 characters'
                            }
                        />
                    </div>
                    <div className="form-group">
                        <label>Delegate To</label>
                        <AsyncSelect
                            name="delegateTo"
                            id="delegateTo"
                            className="react-select-delegate-to"
                            value={newDelegation.delegateTo}
                            isLoading={usersLoading}
                            hideSelectedOptions
                            loadOptions={promiseOptions}
                            onChange={(option) => {
                                if (option) handleToUser(option);
                            }}
                            noOptionsMessage={() =>
                                searchText.length >= 3
                                    ? 'No results found'
                                    : 'Start typing minimum 3 characters'
                            }
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label>From Date</label>
                        <ReactDatePicker
                            className="form-control"
                            selected={newDelegation.startDate}
                            onChange={handleFromDate}
                            dateFormat={dateFormat}
                            selectsStart
                            startDate={newDelegation.startDate}
                            endDate={newDelegation.endDate}
                            minDate={new Date()}
                        />
                    </div>
                    <div className="form-group">
                        <label>To Date</label>
                        <ReactDatePicker
                            className="form-control"
                            selected={newDelegation.endDate}
                            onChange={handleToDate}
                            dateFormat={dateFormat}
                            selectsEnd
                            startDate={newDelegation.startDate}
                            endDate={newDelegation.endDate}
                            minDate={getNextDate(newDelegation.startDate)}
                        />
                    </div>
                    {/* <div className="form-group toggle-btn">
                        <label>Edit Access</label>
                        <Toggle
                            isChecked={editAccess}
                            isDisabled={false}
                            onclick={() => setEditAccess(!editAccess)}
                        />   
                    </div>
                    <div className="form-group toggle-btn">
                        <label>Approval Access</label>
                        <Toggle
                            isChecked={approvalAccess}
                            isDisabled={false}
                            onclick={() => setApprovalAccess(!approvalAccess)}
                        />
                    </div> */}
                </div>
                <div className="form-row">
                    <div className="form-group save-btn">
                        <button
                            type="button"
                            className="primary-btn"
                            disabled={delegationLoading}
                            onClick={() => handleSave()}
                        >
                            Delegate
                        </button>
                    </div>
                </div>
            </div>
            <div className="table-section">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, i) => (
                                    <th
                                        {...column.getHeaderProps({ style: { textAlign: 'left' } })}
                                    >
                                        <div
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps(),
                                            )}
                                        >
                                            {column.render('Header')}
                                            {headerGroup.headers[0] === column && (
                                                <div className="document-approvers-filters">
                                                    <input
                                                        type="text"
                                                        name="doc-number"
                                                        id="doc-number"
                                                        placeholder="Search here..."
                                                        className="input-search"
                                                        value={delegateFromFilter as string}
                                                        onChange={(e) => {
                                                            setDelegateFromFilter(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            {headerGroup.headers[1] === column && (
                                                <div className="document-approvers-filters">
                                                    <input
                                                        type="text"
                                                        name="doc-number"
                                                        id="doc-number"
                                                        placeholder="Search here..."
                                                        className="input-search"
                                                        value={delegateToFilter as string}
                                                        onChange={(e) => {
                                                            setDelegateToFilter(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            {column.isSorted && (
                                                <SortIcon
                                                    className={`sort ${
                                                        column.isSortedDesc ? 'desc' : 'asc'
                                                    } `}
                                                />
                                            )}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        if (cell.column.Header === 'Delete') {
                                            return (
                                                <td>
                                                    {cell.row.original.deleteEnable ? (
                                                        <DeleteSvg
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                console.log(cell);
                                                                deleteDelegation(cell.value);
                                                            }}
                                                        />
                                                    ) : null}
                                                </td>
                                            );
                                        }
                                        return (
                                            <td
                                                className={cell.column.id}
                                                {...cell.getCellProps({
                                                    style: {
                                                        minWidth: cell.column.minWidth
                                                            ? cell.column.minWidth
                                                            : 'auto',
                                                    },
                                                })}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <Pagination
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                />
            </div>
        </div>
    );
}

export default Delegations;