/* eslint-disable no-debugger */
import React, { ChangeEvent, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { InvalidSvg, PlusIconSvg } from '../../assets/images';
import { getWordDocPath } from '../../API/convertToWordDoc';
import './TemplateModel.scss';
import { useDocumentContext } from '../../contexts/document-provider';

// error part do
// eslint-disable-next-line max-lines-per-function
interface Props {
    documentId: number;
    closeModel: () => void;
    templatesForDropdown: any;
}
// default data to be modified
// eslint-disable-next-line max-lines-per-function
const TemplateModel: React.FC<Props> = ({
    documentId,
    closeModel,
    templatesForDropdown,
}) => {
    // eslint-disable-next-line max-lines-per-function
    const {fetchAttachments} = useDocumentContext();
    const [template, setTemplate] = useState(templatesForDropdown.length > 0 ? templatesForDropdown[0] : "");
    const fetchWordDoc = async () => {
        const wordDocPath = await getWordDocPath(documentId, template);
        await fetchAttachments()
        if (wordDocPath?.apiStatus === 'SUCCESS')
            toast.success(wordDocPath.message);
        else if (wordDocPath)
            toast.error(wordDocPath.message);
        else
            toast.error("Something went wrong");
        closeModel();


    };
    const handleTemplateChange = async (event: ChangeEvent<HTMLSelectElement>) => {
        setTemplate(event.target.value);
    };

    return (
        <Modal className="template-model" isOpen onRequestClose={closeModel}>
            <PlusIconSvg className="close-btn" onClick={closeModel} />
            <div className="modal-body">
                <p>Select a template to generate Word Document</p>
                <div className="add-template-input-group">

                    <select name="addTemplate" onChange={handleTemplateChange}>
                        {templatesForDropdown.map((templateName: string) => (
                            <option
                                key={templateName}
                                label={templateName}
                                value={templateName}
                            />
                        ),
                        )}
                    </select>
                    <div className="button-group">
                        <button
                            type="button"
                            className="btn"
                            onClick={() => {
                                fetchWordDoc();
                            }}
                        >
                            Generate
                        </button>
                    </div>
                </div>



            </div>
        </Modal>
    );
};
export default TemplateModel;