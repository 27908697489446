/* eslint-disable no-debugger */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Button } from "react-bootstrap";
import TemplateModel from './TemplateModal';
import { getTemplatesForDropDown } from '../../API/templates';

const GenerateWordDocButton: React.FC<{ documentId: number }> = ({ documentId }) => {
    const [showModal, setShowModal] = useState(false);
    const [templatesForDropDown, setTemplates] = useState({});
    const showModel = async () => {
        setShowModal(true);
    };
    const closeModel = async () => {
        setShowModal(false);
    };
    const getTemplatesList = async () => {
        const res = await getTemplatesForDropDown();
        if (res?.apiStatus === 'SUCCESS') {
            setTemplates(res.templates);
        }
    };
    useEffect(() => {
        getTemplatesList();
    }, []);
    return <>
        <Button type="submit" className="btn" onClick={showModel}> Generate DOC</Button>
        {showModal && (
            <TemplateModel
                closeModel={closeModel}
                templatesForDropdown={templatesForDropDown}
                documentId={documentId} />
        )}

    </>;
}
export default GenerateWordDocButton;