import { IAPIResponseStatus } from "../interfaces";
import { getRequest } from "../utils/api-request";

const getTemplatesForDropDown = async (): Promise<
    | { apiStatus: IAPIResponseStatus;templates:string[] }
    | undefined
> => {
    try {
        const response = await getRequest(`templates`);;
        if (response?.apiStatus === 'SUCCESS')
            return {
                apiStatus: response.apiStatus,
                templates:response.response.templates,
            };
    
        // eslint-disable-next-line no-else-return
        else if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus,templates:[] };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};
// eslint-disable-next-line import/prefer-default-export
export {getTemplatesForDropDown};