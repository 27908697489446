import { IAPIResponseStatus } from "../interfaces";
import { getRequest } from "../utils/api-request";

const getWordDocPath = async (documentId:number, templateName:string): Promise<
    | { apiStatus: IAPIResponseStatus;message:string  }
    | undefined
> => {
    try {
        const response = await getRequest(`documents/generate-word-file?templateName=${templateName}&id=${documentId}`);
        if (response?.apiStatus === 'SUCCESS')
            return {
                apiStatus: response.apiStatus,
                message:response.response.message,
            };
    
        // eslint-disable-next-line no-else-return
        else if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus,  message:response.response.message};
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};
// eslint-disable-next-line import/prefer-default-export
export {getWordDocPath};